import React from 'react';
import { Spinner } from 'reactstrap';
import Logo from './logo.png';
import './Loading.css';

export default function Loading(props) {
  return (
    <div className="section-loading">
      <div className={`container-loading`}>
        <div className="loading">
          {props.icon && (
            <div className="loading-img">
              <img
                src={Logo}
                style={{ width: '50px', height: '50px' }}
                alt="loading"
              />
            </div>
          )}
          <Spinner
            color="success"
            style={{
              width: props.icon ? '5rem' : '3rem',
              height: props.icon ? '5rem' : '3rem',
            }}
            className="spinner"
          />
        </div>
      </div>
    </div>
  );
}
