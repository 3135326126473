const constant = {};

constant.SET_ID_NOTIF = 'SET_ID_NOTIF';

constant.CHAT_NOTIF = 'CHAT_NOTIF';
constant.BILLMONTHLY_NOTIF = 'BILLMONTHLY_NOTIF';

constant.REPORT_PAYMENT_ANSWER = 'REPORT_PAYMENT_ANSWER';
constant.REPORT_PAYMENT = 'REPORT_PAYMENT';
constant.REPORT_PAYMENT_COMPLAIN = 'REPORT_PAYMENT_COMPLAIN';
constant.PAYMENTS = 'PAYMENTS';

constant.EMERGENCY = 'EMERGENCY';
constant.COMPLAIN = 'COMPLAIN';
constant.INFORMATION = 'INFORMATION';

constant.BILL_FROM_CHECKER = 'BILL_FROM_CHECKER';
constant.PURCHASE_TOKEN = 'PURCHASE_TOKEN';
constant.NOMINAL_PRICE = 'NOMINAL_PRICE';
constant.MENUS = 'MENUS';

constant.RESERVASI = 'RESERVASI';

constant.PAYMENT_LIST = 'PAYMENT_LIST';

export default constant;
